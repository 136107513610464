import SmallLogoAnimated from 'src/components/icons/SmallLogoAnimated'

import { COLOR } from 'src/styles/theme'

export default function Loader(props) {
  const {
    iconWidth,
    iconHeight,
    loadingComplete = false,
    minimumLoaderDuration = 1500,
  } = props
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: COLOR.LIGHT_BACKGROUND,
      }}
    >
      <SmallLogoAnimated
        width={iconWidth || 126}
        height={iconHeight || 168}
        loadingComplete={loadingComplete}
        minimumLoaderDuration={minimumLoaderDuration}
      />
    </div>
  )
}
